export default function scriptsHome(){
    const swiperParceiros = new Swiper(".swiperParceiros", {
        slidesPerView: 1.5,
        spaceBetween: 15,
    
        breakpoints: {
            
            640: {
                slidesPerView: 2,
                spaceBetween: 20,
            },
            768: {
                slidesPerView: 4,
                spaceBetween: 40,
            },
            1024: {
                slidesPerView: 5,
                spaceBetween: 50,
            },
        },
    
        autoplay: {
            delay: 2500,
            disableOnInteraction: false,
        },

    
        pagination: {
            el: ".container-section-swipper .swiper-pagination",
            clickable: true,
            dynamicBullets: true,
        },
        navigation: {
            nextEl: ".container-section-swipper .swiper-button-next",
            prevEl: ".container-section-swipper .swiper-button-prev",
        },
    });
    
    const swiperTabs = new Swiper(".swiperTabs",{
        slidesPerView: 1.3,
            
        breakpoints: {
            
            640: {
                slidesPerView: 2.2,
                
            },
            768: {
                slidesPerView: 3,
                
            },
            1024: {
                slidesPerView: 3,
                
            },
        },
    
        
        navigation: {
            nextEl: ".swiperTabs .swiper-button-next",
            prevEl: ".swiperTabs .swiper-button-prev",
        },
    })

    const swiperDepoimento = new Swiper(".swiperDepoimento",  {
        slidesPerView: 1.2,
            
        breakpoints: {
            
            640: {
                slidesPerView: 1.2,
                
            },
             768: {
                slidesPerView: 2.2,
                
             },
             1024: {
                 slidesPerView: 1.5,
                
             },
        },

        autoplay: {
            delay: 7500,
            disableOnInteraction: false,
        },

        scrollbar: {
          el: "#sucesso .swiper-scrollbar",
          hide: false,
        },
      });

      const swiperCardBlog = new Swiper(".swiperCardBlog", {
        spaceBetween:15,
        slidesPerView: 1.2,

        breakpoints: {
            
            640: {
                slidesPerView: 1.2,
                
            },
             768: {
                slidesPerView: 1.8,
                
             },
              1024: {
                  slidesPerView: 3,
                
              },
        },

      });

      const swiperVideos = new Swiper(".swiperVideos", {
        spaceBetween:15,
        slidesPerView: 1.2,
        

        breakpoints: {
            
            850: {
                slidesPerView: 1,
                
            },
            //  768: {
            //     slidesPerView: 1.8,
                
            //  },
            //   1024: {
            //       slidesPerView: 3,
                
            //   },
        },

        autoplay: {
            delay: 2500,
            disableOnInteraction: true,
        },

    
        pagination: {
            el: ".swiperVideos .swiper-pagination",
            clickable: true,
            dynamicBullets: true,
        },
        navigation: {
            nextEl: ".swiperVideos .swiper-button-next",
            prevEl: ".swiperVideos .swiper-button-prev",
        },

      });

      const swiperCases = new Swiper(".mySwiperCases", {
        slidesPerView: 1,
        breakpoints: {
            650: {
                slidesPerView: 1.2,
               
             },
           
              850: {
                 slidesPerView: 1.3,
                
              },
                1050: {
                    slidesPerView: 1.4,
                    
                },
              1200: {
               slidesPerView: 1.8,
                
              },
        },
        centeredSlides: true,
        // loop:true,
      spaceBetween: 20,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
    
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });

      
      
    

      
      
}


