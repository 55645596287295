export default function scriptsRanking(){
    var swiper = new Swiper(".mySwiper", {
        slidesPerView: '2.2',
        spaceBetween: 15,
        autoplay: {
            delay: 2500,
            disableOnInteraction: true,
        },
        breakpoints: {
            
            640: {
                slidesPerView: 2.3,
                
            },
            768: {
                slidesPerView: 5.3,
                
            },
            1024: {
                slidesPerView: 7,
                
            },
        },
        
        
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });
      var swiper = new Swiper(".mySwiperRanking", {

        slidesPerView: 0.5,

        breakpoints: {
            
            640: {
                slidesPerView: 1,
                
            },
        },

        scrollbar: {
          el: ".swiper-scrollbar",
          hide: true,
        },
      });



      const respostas = document.querySelectorAll('.card')
      respostas.forEach( (item)=>{
  
          item.addEventListener('click', () => {
              respostas.forEach((item) =>{
                  item.classList.remove('ativo');
              });
              if(item.classList.contains('ativo')){
                  item.classList.remove('ativo')
              } else {
                  item.classList.add('ativo');
              }
          })
  
      });
      const modalContato = document.querySelector('.modal-wrapper-contato'); 
    const btnModalEl = document.querySelectorAll('.modal-for-js-contact'); 
    const btnCloseModalEl = document.querySelector('.close-modal'); 

    function closeModal(){
        modalContato.classList.remove('modal-active')
    }

    btnModalEl.forEach(btn => {
        btn.addEventListener('click', (e)=>{
            e.preventDefault();
            modalContato.classList.add('modal-active');
        });
    })

    document.addEventListener('click', (e) => {     
        if(e.target !== modalContato) return  
            closeModal();    
        })
        btnCloseModalEl.addEventListener('click', ()=>{
        closeModal();
        
    });

    const inputs = document.querySelectorAll('.pesquisa');
    const classificacoes = document.querySelectorAll('.classificacao');
    const classificacoesMobile = document.querySelectorAll('.card');

    inputs.forEach(input => {


        input.addEventListener('input',(e) => {
            console.log(e.target.value);
            [...classificacoes,...classificacoesMobile].forEach(item =>{
                const empresa = item.dataset.nome.toLowerCase()
                console.log(empresa);
                if(empresa.includes(e.target.value.toLowerCase())){
                    item.style.display = 'block'
                } else {
                    item.style.display = 'none'
                }
            })
        });
    })

    console.log(classificacoes);



}
