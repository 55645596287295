// IMPORTS
import getElementAndappend from "./modules/getElementAndappend.js";
import menu from "./modules/menu.js";
import scriptsHome from "./modules/scriptsPaginas/home.js";
import scriptsBlog from "./modules/scriptsPaginas/blog.js";
import scriptsSingleBlog from "./modules/scriptsPaginas/single-blog.js";
import scriptsEbook from "./modules/scriptsPaginas/ebook.js";
import scriptsProdutos from "./modules/scriptsPaginas/produtos.js";
import scriptsCardContratos from "./modules/card-contratos.js";
import scriptsSingleProduto from "./modules/scriptsPaginas/single-produto.js";
import scriptsPageCases from "./modules/scriptsPaginas/cases.js";
import scriptsRanking from "./modules/scriptsPaginas/ranking.js";
import scripTabsServico from "./modules/tabs-servico.js";
import scripContato from "./modules/scriptsPaginas/contato";
import SelectConfig from './modules/selectConfig.js'
import Translate from './modules/translate.js'

// PAGES
const PageHome = document.querySelector("#PageHome");
const PageContato = document.querySelector("#PageContato");
const PageBlog = document.querySelector("#PageBlog");
const PageSingleBlog = document.querySelector("#PageSingleBlog");
const PageSingleCase = document.querySelector("#PageSingleCase");
const PageEbook = document.querySelector("#PageEbook");
const PageEbookMateriais = document.querySelector("#PageEbookMateriais");
const PageProdutos = document.querySelector("#PageProdutos");
const PageSingleProduto = document.querySelector("#PageSingleProduto");
const PageCases = document.querySelector("#PageCases");
const PageRanking = document.querySelector("#PageRanking");

// SCRIPT SENHA LOGIN
import mostrarSenha from "./mostrarSenha.js"
import showFormErrors from "./showFormErrors.js";

mostrarSenha()
showFormErrors()

// SECTIONS
import salebrandPortfolio from "./salebrand/salebrandPortfolio.js";
import salebrandSobre from "./salebrand/salebrandSobre.js";
import salebrandClientes from "./salebrand/salebrandClientes.js";
import salebrandContato from "./salebrand/salebrandContato.js";

salebrandPortfolio();
salebrandSobre();
salebrandClientes();
salebrandContato();


async function myscript() {
//   await getElementAndappend(
//       ".svg-container",
//       "../templates/parts/svg.html"
//   );

//   await getElementAndappend(
//       ".footer-container",
//       "../templates/parts/footer.html"
//   );

//   await getElementAndappend(
//       ".menu-container",
//       "../templates/parts/menu.html"
//   );

  if (PageHome) {
      document.body.classList.add("BodyHome");
      console.log('HOMEEE')

      scriptsHome();
      scripTabsServico();
      SelectConfig('.select-lang');
      Translate();



      //
  } else if(PageContato){

      document.body.classList.add("BodyContato");
      scripContato();
      SelectConfig('.select-lang');
      Translate();

  }  else if(PageBlog){

      document.body.classList.add("BodyBlog");

      scriptsBlog();
      SelectConfig('.select-lang');
      Translate();
      //
  } else if(PageSingleBlog){

      document.body.classList.add("BodySingleBlog");

      scriptsSingleBlog();
      SelectConfig('.select-lang');
      Translate();
  } else if(PageSingleCase){

      document.body.classList.add("BodySingleCase");

  }  else if(PageEbook){

      document.body.classList.add("BodyEbook");

      scriptsEbook();
      SelectConfig('.select-lang');
      Translate();
  } else if(PageEbookMateriais){

      document.body.classList.add("PageEbookMateriais");


  } else if(PageProdutos){

      document.body.classList.add("PageProdutos");

      scriptsProdutos();

      scriptsCardContratos();

      scripTabsServico();
      SelectConfig('.select-lang');
      Translate();

  }  else if(PageSingleProduto){

      document.body.classList.add("PageSingleProduto");

      scriptsCardContratos();

      scriptsSingleProduto();
      SelectConfig('.select-lang');
      Translate();


  }  else if(PageCases){

      document.body.classList.add("PageCases");

      scriptsPageCases();
      scriptsProdutos();
      SelectConfig('.select-lang');
      Translate();

  }  else if(PageRanking){

      document.body.classList.add("PageRanking");
      scriptsRanking();
      SelectConfig('.select-lang');
      Translate();
      //
  }

  menu();


}

myscript()

document.addEventListener("DOMContentLoaded", () =>{

        document.body.classList.add("dcl")
        
    }
);
