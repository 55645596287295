export default function scriptsCases(){

    var swiper = new Swiper(".mySwiper", {

        slidesPerView: 1.3,
        spaceBetween: 4,
        breakpoints: {
            
            640: {
                slidesPerView: 2.3,
                
            },
            768: {
                slidesPerView: 3.3,
                
            },
            1024: {
                slidesPerView: 6,
                
            },
        },
        pagination: {
            el: ".mySwiper .swiper-pagination",
            clickable: true,
            dynamicBullets: true,
        },

        autoplay: {
            delay: 2500,
            disableOnInteraction: true,
        },
        navigation: {
          nextEl: "#marcas .swiper-button-next",
          prevEl: "#marcas .swiper-button-prev",
        },
      });
}
