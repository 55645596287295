export default function scriptsContato(){

    const modalContato = document.querySelector('.modal-wrapper-contato'); 
    const btnModalEl = document.querySelectorAll('.modal-for-js-contact'); 
    const btnCloseModalEl = document.querySelector('.close-modal-contato'); 

    function closeModal(){
        modalContato.classList.remove('modal-active')
    }

    btnModalEl.forEach(btn => {
        btn.addEventListener('click', (e)=>{
            e.preventDefault();
            modalContato.classList.add('modal-active');
            console.log(btn)
        });
    })

    document.addEventListener('click', (e) => {     
        if(e.target !== modalContato) return  
            closeModal();    
        })
        btnCloseModalEl.addEventListener('click', ()=>{
        closeModal();
        
    });

    

}
