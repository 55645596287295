export default function scriptsSingleProdutos(){
    
    const respostas = document.querySelectorAll('.perguntas-respostas-wraper')
    respostas.forEach( (item)=>{

        item.addEventListener('click', () => {
            respostas.forEach((item) =>{
                item.classList.remove('ativo');
            });
            if(item.classList.contains('ativo')){
                item.classList.remove('ativo')
            } else {
                item.classList.add('ativo');
            }
        })

    })
}
