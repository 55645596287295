 

 


export default function scriptsEbook(){
    var swiper = new Swiper(".mySwiper", {
        slidesPerView: 2.3,

        breakpoints: {
            
            640: {
                slidesPerView: 2.3,
                
            },
            768: {
                slidesPerView: 5.3,
                
            },
            1024: {
                slidesPerView: 7,
                
            },
        },

        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });
}

const ativarButton = document.querySelectorAll('.conteudo-grid');

 ativarButton.forEach((item) => {
    item.addEventListener('click',() => {
        
        ativarButton.forEach((item) => {
            item.classList.remove('active');
        })
        
        item.classList.add('active');

    });
 });
