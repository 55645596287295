export default function scriptsBlog(){
    const swiper = new Swiper(".mySwiper", {
        slidesPerView: 1,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        pagination: {
            el: ".swiper-setas-wraper .container-section-swipper .swiper-pagination",
            clickable: true,
        },
      });
}
