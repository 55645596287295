export default function scriptsTabsServico(){
    var swiperServico = new Swiper(".swiperServicos", {
        slidesPerView: 1,
        allowTouchMove: false
        // effect:'fade'

    });

 const tabServicos = document.querySelectorAll('.tab-servicos')
 const tabConteudo = document.querySelector('.tab-contant .swiper-slide-active .col-1')
 tabConteudo.style.opacity= 1
 tabConteudo.style.transform='translateY(20px)'
    tabServicos.forEach((tab , index) => {

        tab.addEventListener('click', (e) => {
            
            const tabConteudo = document.querySelector('.tab-contant .swiper-slide-active .col-1')
            tabConteudo.style.opacity= 0
            tabConteudo.style.transform='translateY(20px)'
            const tabImg = document.querySelectorAll('.tab-contant .swiper-slide .col-2')
            tabImg.forEach((img)=>{

                img.style.opacity= 0

            })
            
            setTimeout(()=>{
                swiperServico.slideTo(index, 1000 )
                const imgActive = document.querySelector('.tab-contant .swiper-slide-active .col-2')
                imgActive.style.opacity= 1
            }, 300)

            setTimeout(()=>{
                
                const tabConteudo = document.querySelector('.tab-contant .swiper-slide-active .col-1')
                tabConteudo.style.opacity= 1
                tabConteudo.style.transform='translateY(0px)'
            }, 1100)
            
                
                
                
            
            
            tabServicos.forEach((tab) => {
                tab.classList.remove('active')
            })
            
            e.target.closest('.tab-servicos').classList.add('active')
        })
    })

 //const wraperConteudo = document.getElementsByClassName('.wraper-conteudo')

    // tabServicos.forEach((tab) => {
    //     tab.addEventListener('click', (event) =>{
    //         tabServicos.forEach((tab) => {
    //             tab.classList.remove('active')
    //         })
    //         event.target.closest('.tab-servicos').classList.add('active')
    //         const pegarId = event.target.closest('.tab-servicos').id
    //          var tabContent = document.querySelector(`.tab-contant #${pegarId}`)
                          
    //          const todosConteudos = document.querySelectorAll('.wraper-conteudo')

    //          todosConteudos.forEach((conteudo) => {
    //             conteudo.classList.remove('show')
    //          })
             
    //          tabContent.classList.add('show')
    //     });
    // })
    
    
    
}
