export default function scriptsSingleBlog(){
    const swiperCardBlog = new Swiper(".swiperCardBlog", {
        spaceBetween:15,
        slidesPerView: 1.2,

        breakpoints: {
            
            640: {
                slidesPerView: 1.2,
                
            },
             768: {
                slidesPerView: 1.8,
                
             },
              1024: {
                  slidesPerView: 3,
                
              },
        },
        autoplay: {
            delay: 3500,
            disableOnInteraction: false
        },

      });
}
