export default function scriptsProdutos(){
    
    const swiperTabs = new Swiper(".swiperTabs",{
        slidesPerView: 2.3,
            
        breakpoints: {
            
            640: {
                slidesPerView: 3.2,
                
            },
            768: {
                slidesPerView: 5.4,
                
            },
            1024: {
                slidesPerView: 7.4,
                
            },
        },
    
        
        navigation: {
            nextEl: ".swiperTabs .swiper-button-next",
            prevEl: ".swiperTabs .swiper-button-prev",
        },
    })

    const swiperDepoimento = new Swiper(".swiperDepoimento",  {
        slidesPerView: 1.2,
            
        breakpoints: {
            
            640: {
                slidesPerView: 1.2,
                
            },
             768: {
                slidesPerView: 2.2,
                
             },
             1024: {
                 slidesPerView: 1.5,
                
             },
        },

        autoplay: {
            delay: 9000,
            disableOnInteraction: false,
        },

        scrollbar: {
          el: "#sucesso .swiper-scrollbar",
          hide: false,
        },
      });

      const swiperCardBlog = new Swiper(".swiperCardBlog", {
        spaceBetween:15,
        slidesPerView: 1.2,

        breakpoints: {
            
            640: {
                slidesPerView: 1.2,
                
            },
             768: {
                slidesPerView: 1.8,
                
             },
              1024: {
                  slidesPerView: 3,
                
              },
        }

      });

      
}


