export default function scriptsCardContratos(){

    const ativarCard = document.querySelectorAll('.subtitulo-contratos')
    const removerCard = document.querySelectorAll('.conteudo-contrato')


 
        function cardAtivo(event){
            
                removerCard.forEach((card) =>{
                    card.classList.remove('ativo');
                });
                
                event.target.closest('.conteudo-contrato').classList.toggle('ativo');

            
              
        }
        ativarCard.forEach((card) =>{
            card.addEventListener('click', cardAtivo);
        });
   

   
}
