export default function menu(){
 const botao  =  document.querySelector("#btn-mobile")
 const nav  =  document.querySelector("#header")

 botao.addEventListener("click", function(){
    nav.classList.toggle("active")
    // if (nav.classList.contains("active")){
    //     nav.classList.remove("active")
    // }
    // else {
    //     nav.classList.add("active")
    // }
 })
}
